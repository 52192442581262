.list-small-image {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.image--large {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  background-color: transparent;
}

.image--small {
  width: 100px;
  height: 100px;
  display: inline-block;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
}

.image--small::part(image) {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.button-close {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  font-size: 50px;
  line-height: 50px;
  transform: rotate(45deg);
  color: red;
}

.button-remove-image {
  position: absolute;
  font-size: 25px;
  top: 0px;
  right: 0px;
  color: red;
  cursor: pointer;
}

.button-remove-image:hover {
  transform: scale(1.2);
}
