.button-add-image {
  width: 100px;
  height: 100px;
  flex-direction: column;
  --border-style: dashed;
}

.button-add-image--icon {
  font-size: 50px;
  color: black;
}
