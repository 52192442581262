.border-black {
  border-bottom: 1px#636e72 solid;
  border-left: 1px#636e72 solid;
  border-radius: 5px;
}
.card * {
  font-size: 1rem;
  font-weight: 500;
}

.my-custom-modal-css .modal-wrapper {
  height: 35%;
  width: 80%;
  top: 15%;
  position: absolute;
  display: block;
  border-radius: 10px;
}
.my-custom-modal-css .modal-wrapper .header {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
}

.my-custom-modal-css .modal-wrapper .content {
  width: 90%;
  margin: 0 auto;
}

.my-custom-modal-css .modal-wrapper .content .quantity-input {
  border: 2px solid #ff602ead;
  border-radius: 10px;
  padding: 10px !important;
  font-size: 20px;
}

.my-custom-modal-css .modal-wrapper .footer {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.my-custom-modal-css .modal-wrapper .footer .footer-btn {
  width: 80px;
  margin-right: 10px;
}
